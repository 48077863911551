import ReactDOM from "react-dom";
import React from "react";
import {
  ChatOpenMode,
  IChatInitParameters,
  IDivHostedAppInitParameters,
  ISessionTopicPayload,
  ITopic,
  ITopicSubscription,
} from "@sparkware/uc-sdk-core";
import { IChatWidgetAppController } from "./chat-widget.controller.interface";
import { ServiceFactory } from "../services/service-factory";
import { ChatContextProvider, useChatContext } from "../services/contexts/chat-context";
import { clearScriptTag, isNotSet } from "../utils/utility";
import { RootComponent } from "../components/chat/chat-root.component";
import {
  CHAT_APP_ID,
  CHAT_BUBBLE_APP_ID,
  CHAT_TOPIC_HEADERCHAT_APP_ID,
  ChatAvatarType,
  ORACLE_SDK_SCRIPT,
} from "../types/global";
import { IServiceFactory } from "../services/service-factory.interface";
import { Check } from "@sparkware/app-utils";
import { getErrorMessage } from "../utils/error";

export class ChatWidgetAppController implements IChatWidgetAppController {
  private _subscriptions: ITopicSubscription[] = [];
  private _chatContainerElement: HTMLElement | null = null;

  currentAvatar: ChatAvatarType = ChatAvatarType.agent;

  async init(appInitParams: IChatInitParameters): Promise<void> {

    this._chatContainerElement = document.getElementById(
      appInitParams.applicationContext.launchInfo.containerID!
    );
    const serviceFactory = new ServiceFactory(
      appInitParams.messageBrokerChannels
    );

    ReactDOM.render(
      <React.StrictMode>
        <ChatContextProvider value={{ services: serviceFactory }}>
          <RootComponent />
        </ChatContextProvider>
      </React.StrictMode>,
      this._chatContainerElement
    );

    try {
      // start loading the application
      if (appInitParams.userInfo.userMode === "authenticated" ){
        await serviceFactory.chatData.loadAuthenticatedInitData(appInitParams, false);
      }
      else {
        await serviceFactory.chatData.loadUnAuthenticatedInitData(appInitParams, false);
      }
    } catch (ex) {
      serviceFactory.messageBroker.initFailed(CHAT_APP_ID, {
        errorCode: 1,
        errorDescription: getErrorMessage(ex),
      });

      return;
    }

    //subscribe to Events
    this.subscribeToMBAppClosed(appInitParams, serviceFactory);
    this.subscribeToMBMinimizeApp(appInitParams, serviceFactory);
    this.subscribeToMBAppMaximized(appInitParams, serviceFactory);
    this.subscribeToMBAppMinimized(appInitParams, serviceFactory);
    this.subscribeToMBAppInitFailed(appInitParams, serviceFactory);
    this.subscribeToMBLogout(appInitParams, serviceFactory);
  }

  private destroyChat = async (services: IServiceFactory) => {
    if (this._chatContainerElement) {
      ReactDOM.unmountComponentAtNode(this._chatContainerElement);
      this._chatContainerElement = null;
    }
    try {
      if (Check.isNullOrUndefined(services)) return;

      const activeChat = services.chatService?.activeChat;
      activeChat && activeChat.sendMessage("WindowClosedByUser");
      setTimeout(async () => {
        if (!activeChat) return;

        try {
          await activeChat.destroy();
          if (activeChat.isConnected()) {
            await activeChat.disconnect();
          }

          services.logger.info("The oracle SDK connection was closed");
          services.chatService.resetActiveChat();
        } catch (e) {
          services.logger.error(
            "Error while closing the chat service",
            getErrorMessage(e)
          );
          throw e;
        }

      });
    } catch (err) {
      services.logger.error("Error while destroying the chat", err);
    } finally {
      clearScriptTag(ORACLE_SDK_SCRIPT);

      this._subscriptions.forEach((s) => s.unsubscribe());
      this._subscriptions = [];
      if (services) {
        services.dispose(); // throws error: on topic appClosed in channel session failed Error: Method not implemented.
      }
    }
  };

  private subscribeToMBAppClosed(
    appInitParams: IDivHostedAppInitParameters,
    serviceFactory: IServiceFactory
  ) {
    this._subscriptions.push(
      appInitParams.messageBrokerChannels.session.topics.appClosed.subscribe(
        (payload) => {
          if (payload.appID === appInitParams.applicationContext.appID) {
            this.destroyChat(serviceFactory);
          }
        }
      )
    );
  }

  private subscribeToMBMinimizeApp(
    appInitParams: IDivHostedAppInitParameters,
    serviceFactory: IServiceFactory
  ) {
    const { logger } = serviceFactory;
    this._subscriptions.push(
      appInitParams.messageBrokerChannels.session.topics.minimizeApp.subscribe(
        (payload) => {
          const { brandID, subBrandID } = appInitParams.applicationContext;
          //send avatar image
          try {
            if (payload.appID === appInitParams.applicationContext.appID) {
              if (
                serviceFactory.chatData?.chatOpenMode !== ChatOpenMode.SOF &&
                !serviceFactory.chatData?.chatHours?.isOpened
              ) {
                if (
                  !serviceFactory.chatData.analyticsEventData.userSentMessage
                ) {
                  logger.debug("App closed");
                }
                serviceFactory.messageBroker.appClosed(
                  CHAT_APP_ID,
                  serviceFactory.chatData.chatInitParameters?.correlationID!,
                  serviceFactory.chatData.chatInitParameters?.applicationContext
                    .launchInfo!
                );
                return;
              }

              serviceFactory.messageBroker.appMinimized(
                CHAT_APP_ID,
                CHAT_TOPIC_HEADERCHAT_APP_ID,
                {
                  appID: CHAT_APP_ID,
                  appData: { brandID, subBrandID },
                }
              );
            }
          } catch (ex) {
            logger.error(
              "subscribeToMBMinimizeApp had an error",
              getErrorMessage(ex)
            );
          }
        }
      )
    );
  }

  private subscribeToMBAppInitFailed(
    appInitParams: IDivHostedAppInitParameters,
    serviceFactory: IServiceFactory
  ) {
    this._subscriptions.push(
      appInitParams.messageBrokerChannels.session.topics.appInitFailed.subscribe(
        (payload) => {
          if (payload.appID == CHAT_APP_ID || payload.appID == CHAT_BUBBLE_APP_ID) {
            this.destroyChat(serviceFactory);
          }
        }
      )
    );
  }

  private subscribeToMBLogout(
    appInitParams: IDivHostedAppInitParameters,
    serviceFactory: IServiceFactory
  ) {
    this._subscriptions.push(
      appInitParams.messageBrokerChannels.identity.topics.logout.subscribe(
        (payload) => {
          serviceFactory.livePersonService.closeLPChatAtLogout();
        }
      )
    );
  }

  private subscribeToMBAppMaximized(
    appInitParams: IDivHostedAppInitParameters,
    serviceFactory: IServiceFactory
  ) {
    this._subscriptions.push(
      appInitParams.messageBrokerChannels.session.topics.appMaximized.subscribe(
        (payload) => {
          let scrollDiv = document.getElementsByClassName(
            "oda-chat-conversation-container"
          );
          if (scrollDiv !== null && scrollDiv.length > 0) {
            scrollDiv[0].lastElementChild?.scrollIntoView({
              behavior: "smooth",
            });
          }
        }
      )
    );
  }

  private subscribeToMBAppMinimized(
    appInitParams: IDivHostedAppInitParameters,
    serviceFactory: IServiceFactory
  ) {
    try {
      this._subscriptions.push(
        appInitParams.messageBrokerChannels.session.topics.appMinimized.subscribe(
          (payload) => {
            //user clicked on minimize button or overley
          }
        )
      );
    } catch (ex) {
    }
  }

  private unsubscribeAll() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  private subscribeTo<T = ISessionTopicPayload>(
    topicFn: () => ITopic<T>,
    payloadFn: (payload: T) => void
  ) {
    if (!isNotSet(topicFn)) return;

    this._subscriptions.push(
      topicFn().subscribe((payload) => payloadFn(payload))
    );
  }
}


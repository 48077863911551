import { Check } from "@sparkware/app-utils";

export const validationErrorFactory =
  (componentName: string) => (message: string) =>
    new Error(`${componentName}: ${message}`);
export const isNotSet = (value: unknown) => {
  return value === undefined || value === null || value === "";
};

type Nullable<T> = { [P in keyof T]: T[P] | null };

export const isKeyof = <T extends object>(
  obj: T,
  possibleKey: keyof any
): possibleKey is keyof T => {
  return possibleKey in obj;
};

export function loadScriptTag(id: any, url: any): Promise<void> {
  return new Promise((resolve, reject) => {
    var scriptTag = document.createElement("script");
    scriptTag.id = id;
    scriptTag.src = url;
    scriptTag.onload = () => {
      resolve();
    };
    scriptTag.onerror = (err) => {
      console.error(`Failed to load script from ${url}`, err);
      reject(err);
    };
    document.head.appendChild(scriptTag);
  });
}

export function clearScriptTag(id: any) {
  var script = document.getElementById(id);
  script?.remove();
}

export function clearHtmlElementContent(id: any) {
  var el = document.getElementById(id);
  if (!Check.isNullOrUndefined(el)) {
    el.innerHTML = "";
  }
}

export function createErrorPayload(
  errorDescription: string,
  errorCode: number = 1
) {
  return {
    errorCode,
    errorDescription,
  };
}

import React from "react";
import { observer, PropTypes } from "mobx-react";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { ActionID, AppIdentifiers } from "@sparkware/uc-sdk-core";
import { useChatContext } from "../../services/contexts/chat-context";
import { isNotSet, loadScriptTag } from "../../utils/utility";
import { OutOfHoursPopup } from "./out-of-hours-help.component";
import { reaction } from "mobx";
import { UrgentMessagePopup } from "./urgent-message-popup.component";
import { QuestionMarkSVG } from "../../resources/SVG/question-mark";
import { CopyIconSVG } from "../../resources/SVG/copy-icon";
import Clipboard from "@react-native-clipboard/clipboard";
import { ChatIconSVG } from "../../resources/SVG/chat-icon";
import { ChatIconHoverSVG } from "../../resources/SVG/chat-icon-hover";
import { ChatIconDisabledSVG } from "../../resources/SVG/chat-icon-disabled";
import { FaqIconSVG } from "../../resources/SVG/faq-icon";
import { FaqIconHoverSVG } from "../../resources/SVG/faq-icon-hover";
import { EmailIconSVG } from "../../resources/SVG/email-icon";
import { EmailIconHoverSVG } from "../../resources/SVG/email-icon-hover";
import { CloseButtonSVG } from "../../resources/SVG/close-button";
import { FaqReport } from "../faq/faq-report.component";

const ContainerMain = styled.div`
  font-family: ${(props: any) => props.theme.fontFamily};
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: ${(props: any) => props.theme.colors.helpCenterBackground};

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #717171;
    border-radius: 3px;
  }
`;

const NavigationBarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 9999;
  height: 50px;
  width: 100%;
  float: right;
  border-bottom: 1px solid #4c4c4c;
  text-align: center;
  line-height: 50px;
  background: ${(props: any) => props.theme.colors.primaryHeader};
`;

const CloseButton = styled.div`
  float: right;
  position: relative;
  margin-right: 4.8%;
  margin-top: 6px;
  cursor: pointer;
  z-index: 20000;
`;

const HeaderText = styled.div`
  color: ${(props: any) => props.theme.colors.secondary};
  letter-spacing: 0.98px;
  font-family: ${(props: any) => props.theme.fontFamily};
  font-weight: bold;
  padding-left: 50px;
  font-size: 14px;
`;

interface AmandaIconProps {
  isUrgentMessageVisible: boolean;
}
const AmandaIcon = styled.img<AmandaIconProps>`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props: any) => {
    if (props.isUrgentMessageVisible) {
      return "42px";
    } else {
      return "82px";
    }
  }};
  width: 72px;
  height: 72px;
`;

const AmandaFAQ = styled.div<{ isRtl: boolean }>`
  position: absolute;
  margin-left: ${(props: any) => (props.isRtl ? "" : "54%")};
  margin-right: ${(props: any) => (props.isRtl ? "54%" : "")};
  > p#svgQuestionMark { 
    padding: ${(props: any) => (props.isRtl ? "0 153px" : "0 4px")};
  }
  margin-top: -23px; 
`; // transform: ${props => props.isRtl ? "scale(-1, 1)": ""};


const GreetingContainer = styled.div`
  margin-top: 20px;
  padding-bottom: 15px;
`;

const GreetingParagraph = styled.div`
  font-family: ${(props: any) => props.theme.fontFamily} !important;
  text-align: center;
  color: ${(props: any) => props.theme.colors.helpCenterMainPageColors.greetingMessage};
  font-weight: ${(props: any) => props.theme.fontWeight.weights.greetingMessage};
  margin-bottom: 0px;
  font-size: 13px;
  width: 270px;
  margin: 0 auto;
`;

interface ButtonContainerProps {
  isOnlyButtonInRow: boolean;
}

interface FaqButtonContainerProps extends ButtonContainerProps{
  floatLeft: boolean | undefined;
}

const FAQButtonContainer = styled.div<FaqButtonContainerProps>`
  text-align: center;
  width: ${(props: any) => (props.isOnlyButtonInRow ? "100%" : "48%")};
  margin-top: 15px;
  float: ${(props: any) => (props.floatLeft ? "left" : "right")};
`;

const ChatButtonContainer = styled.div<ButtonContainerProps>`
  text-align: center;
  width: ${(props: any) => (props.isOnlyButtonInRow ? "100%" : "48%")};
  float: left !important;
  margin-top: 15px;
  svg {
    overflow: visible !important;
  }

`
const EmailButtonContainer = styled.div<ButtonContainerProps>`
  text-align: center;
  width: ${(props: any) => (props.isOnlyButtonInRow ? "100%" : "48%")};
  float: right !important;
  margin-top: 15px;
`;

const ButtonsContainer = styled.div`
  margin-left: 30px;
  margin-right: 30px;
`;
const DefaultButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HoverButtonIcon = styled.div`
  display: none;
`;
const DisabledButtonIcon = styled.div``;

const HelpButton = styled.button`
  height: 45px;
  background: ${(props: any) => props.theme.colors.helpCenterButtonsBackground}
    0% 0% no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid ${(props: any) => props.theme.colors.buttonBorderColor};
  width: 100%;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  :hover ${DefaultButtonIcon} {
    display: none;
  }

  :hover ${HoverButtonIcon} {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :hover {
    background: ${(props: any) =>
      props.theme.colors.helpCenterButtonsBackground};
  }

  :active {
    border: 2px solid ${(props: any) => props.theme.colors.secondaryHeader};
  }

  :focused {
    background: ${(props: any) =>
      props.theme.colors.helpCenterButtonsBackground};
  }
`;

const DisabledButton = styled.button`
    height: 45px;
    background: ${(props: any) =>
      props.theme.colors.popupBackground}; 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 100%;
    border: 1px solid ${(props: any) => props.theme.colors.outOfHoursBorder};

    :hover {
        background: ${(props: any) => props.theme.colors.popupBackground};
        border: 1px solid ${(props: any) =>
          props.theme.colors.outOfHoursBorder};
    }
`;

const ButtonLabel = styled.div`
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.7px;
  color: ${(props: any) => props.theme.colors.userMessageBackground};
  margin-top: 10px;
`;

const DisabledButtonLabel = styled.div`
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.7px;
  color: ${(props: any) => props.theme.colors.outOfHoursBorder};
  margin-top: 10px;
`;

const UserName = styled.span`
  color: ${(props: any) => props.theme.colors.helpCenterMainPageColors.userName};
`;

const Footer = styled.div<{ hasTopFaqReport: boolean }>`
/*position: absolute;
  bottom: ${(props: any) => (props.hasTopFaqReport ? "-115px" : "10px")};
*/  
  width: 100%;
  text-align: center;
  line-height: 13px;
  float: right;
  padding-top: 25px;
`;
const FooterSpan = styled.span`
  font-size: 10px;
  letter-spacing: 0.4px;

  color: ${(props: any) => props.theme.colors.iconColors.copyIcon};
  opacity: ${(props: any) => props.theme.fontWeight.opacity.versionNumber};
  font-weight: normal;
  cursor: pointer;
`;

const InfoIcon = styled.img``;
const InfoIconHover = styled.img`
  display: none;
`;

const InfoButton = styled.div<{ isOnlyButtonInRow: boolean }>`
  position: absolute;
  margin-left: ${(props: any) => props.isOnlyButtonInRow ? "326px": "152px" };
  margin-top: -55px; 
  cursor: pointer;

  :hover ${InfoIcon} {
    display: none;
  }

  :hover ${InfoIconHover} {
    display: inline;
  }
`;

interface UrgentMessageBannerProps {
  isLargeText: boolean;
}

const UrgentMessageBanner = styled.div<UrgentMessageBannerProps>`
  background-color: ${(props: any) =>
    props.theme.colors.urgentMessageBackground};
  height: 40px;
  width: 100%;
  margin-top: 50px;
  cursor: ${(props: any) => (props.isLargeText ? "pointer" : "auto")};
`;

const UrgentMessageText = styled.div<{ isRtl: boolean }>`
  width: 84%;
  margin-left: ${(props: any) => (props.isRtl ? "2%" : "14%")};
  margin-right: ${(props: any) => (props.isRtl ? "14%" : "2%")};
  padding-top: 6px;
  color: ${(props: any) => props.theme.colors.secondaryText};
  font-size: 12px;
  line-height: 14px;

  > a {
    color: ${(props: any) => props.theme.colors.secondary};
    text-decoration: underline;

    &:hover,
    &:active {
      color: ${(props: any) => props.theme.colors.secondary};
    }

    &:visited {
      color: ${(props: any) => props.theme.colors.secondary};
    }
  }
`;

const UrgentMessageInfoImage = styled.img<{ isRtl: boolean }>`
  position: absolute;
  margin-top: 8px;
  margin-left: ${(props: any) => (props.isRtl ? "" : "4%")};
  margin-right: ${(props: any) => (props.isRtl ? "4%" : "")};
  width: 24px;
  height: 24px;
`;

export const Help: FC = observer(() => {
  const services = useChatContext().services;

  const {
    helpCenterService,
    playerService,
    logger,
    messageBroker,
    applicationService,
    chatData,
    languageService,
  } = useChatContext().services;

  const isRtl: boolean =
    document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl" ||
    document.getElementsByTagName("html")[0].classList.contains("cgp-rtl");

  const onCloseClick = () => {
    messageBroker.appClosed(
      AppIdentifiers.CRHelpCenter,
      applicationService.appInitParameters?.applicationContext.launchInfo
        .businessCorrelationID!
    );
    logger.debug("App closed.", {
      chatAppId: AppIdentifiers.CRHelpCenter,
      launchInfo:
        applicationService.appInitParameters?.applicationContext.launchInfo,
    });
  };

  const onFAQClick = () => {
    helpCenterService.openFAQ();
  };

  const onEmailClick = () => {
    helpCenterService.openEmail();
  };

  const onOpenChatClick = () => {
    let shouldOpenChat = chatData.isPlayerAuthenticated ? 
      chatData.chatSettings?.showChatButtonAuthenticated : 
      chatData.chatSettings?.showChatButtonUnauthenticated;

    if (!shouldOpenChat){
      return;
    }

    helpCenterService.openChat();
  };

  const onCopySuportIDClick = () => {
    Clipboard.setString(playerService.playerData?.cid.toString()!);
  };

  const onCopyVersionClick = () => {
    Clipboard.setString(playerService.playerData?.currentClientVersion!);
  };

  const [isOutOfHoursPopupOpen, setIsOutOfHoursPopupOpen] = useState(false);

  const closeOutOfHoursPopup = () => {
    setIsOutOfHoursPopupOpen(false);
  };

const shouldRenderChatButton = 
  chatData.isPlayerAuthenticated ? 
      chatData.chatSettings?.showChatButtonAuthenticated : 
      chatData.chatSettings?.showChatButtonUnauthenticated;

  const numberOfButtons = () => {
  let result = 0;
  if (shouldRenderChatButton) { result ++};
  if (helpCenterService.hasEmail) {result ++};
  if (helpCenterService.hasFaq) {result ++}

  return result;
}

 const renderButtons  = (first: (() => JSX.Element) | null , second: (() => JSX.Element) | null , third: (() => JSX.Element) | null) => {
  if (numberOfButtons() < 3) {
     return ( <ButtonsContainer id="buttonsContainer">
        {first && <div>{first?.call(this)}</div>}
        {second && <div>{second?.call(this)}</div>}
        {third && <div>{third?.call(this)}</div>}
      </ButtonsContainer>)
  } else {
      return (
      <ButtonsContainer id="buttonsContainer">
        <div>
          <div>{first?.call(this)}</div>
          <div>{second?.call(this)}</div>
        </div>
        <div>
          <div>{third?.call(this)}</div>
        </div>
      </ButtonsContainer>)
      }
  }

  const renderFAQButton = () => {
    return (<FAQButtonContainer 
              id="faqButtonContainer"
              isOnlyButtonInRow = {numberOfButtons() == 1}
              floatLeft = {!shouldRenderChatButton || numberOfButtons() == 3}
              >
              <HelpButton
                  onClick={onFAQClick}
                  disabled={isNotSet(helpCenterService.faqPageUrl)}
                >
                  <DefaultButtonIcon>
                    <FaqIconSVG></FaqIconSVG>
                  </DefaultButtonIcon>
                <HoverButtonIcon id="hoverIconFaq">
                    <FaqIconHoverSVG></FaqIconHoverSVG>
                  </HoverButtonIcon>
                </HelpButton>
                <ButtonLabel>
                  {languageService.translate(services.themes.currentTheme.textKeys.faqButtonLabel)}
                </ButtonLabel>
        </FAQButtonContainer>)
  }

  const renderEmailButton = () => {
    return ( <EmailButtonContainer
          id="emailButtonContainer"
          isOnlyButtonInRow = {numberOfButtons() == 1}
        >
          <HelpButton
            onClick={onEmailClick}
            disabled={isNotSet(helpCenterService.emailPageUrl)}
          >
            <DefaultButtonIcon>
              <EmailIconSVG></EmailIconSVG>
            </DefaultButtonIcon>
            <HoverButtonIcon id="hoverIconEmail">
              <EmailIconHoverSVG></EmailIconHoverSVG>
            </HoverButtonIcon>
          </HelpButton>
          <ButtonLabel>
            {languageService.translate("Email_Button_Label")}
          </ButtonLabel>
        </EmailButtonContainer>)
  }

  const renderChatButton = () => {
    if (chatData.chatHours?.isOpened) {
      return (
        <ChatButtonContainer
          id="chatButtonContainer"
          isOnlyButtonInRow= {numberOfButtons() % 2 == 1}
        >
          <HelpButton onClick={onOpenChatClick}>
            <DefaultButtonIcon>
              <ChatIconSVG></ChatIconSVG>
            </DefaultButtonIcon>
            <HoverButtonIcon id="hoverIconChat">
              <ChatIconHoverSVG></ChatIconHoverSVG>
            </HoverButtonIcon>
          </HelpButton>
          <ButtonLabel>
            {languageService.translate(services.themes.currentTheme.textKeys.chatButtonLabel)}
          </ButtonLabel>
        </ChatButtonContainer>
      );
    } else {
      return (
        <ChatButtonContainer
          id="chatButtonContainer"
          isOnlyButtonInRow= {numberOfButtons() % 2 == 1}>
          <DisabledButton onClick={() => setIsOutOfHoursPopupOpen(true)}>
            <DisabledButtonIcon>
              {" "}
              <ChatIconDisabledSVG></ChatIconDisabledSVG>{" "}
            </DisabledButtonIcon>
          </DisabledButton>
          <InfoButton
            onClick={() => setIsOutOfHoursPopupOpen(true)}
            isOnlyButtonInRow={
              (helpCenterService.hasEmail && helpCenterService.hasFaq) || ((!helpCenterService.hasEmail && !helpCenterService.hasFaq))
            }
          >
            <InfoIcon
              src={services.themes.currentTheme.assets.images.infoIcon}
            />
            <InfoIconHover
              src={services.themes.currentTheme.assets.images.infoIconHover}
            />
          </InfoButton>
          <DisabledButtonLabel>
            {languageService.translate(services.themes.currentTheme.textKeys?.chatButtonLabel)}
          </DisabledButtonLabel>
        </ChatButtonContainer>
      );
    }
  };

  const renderPageFooter = () => {
    if (!playerService.isAuthenticated) {
      return null;
    }
    console.log(`faq = ${chatData.chatSettings?.FAQreportId}`);

    return (
      <Footer
        id="footer"
        hasTopFaqReport={chatData.chatSettings?.FAQreportId != undefined}
      >
        {playerService.playerData?.cid && (
          <p onClick={onCopySuportIDClick}>
            <FooterSpan>
              {languageService
                .translationFor("SupportId_Message")
                .withParams({ playerId: playerService.playerData?.cid })}
            </FooterSpan>{" "}
            &nbsp;
            <CopyIconSVG></CopyIconSVG>
          </p>
        )}
        {playerService.playerData?.currentClientVersion && (
          <p onClick={onCopyVersionClick}>
            <FooterSpan>
              {languageService
                .translationFor("CurrentVersion_Message")
                .withParams({
                  currentClientVersion:
                    playerService.playerData?.currentClientVersion,
                })}
            </FooterSpan>{" "}
            &nbsp;
            <CopyIconSVG></CopyIconSVG>
          </p>
        )}
      </Footer>
    );
  };

  const [isUrgentMessageTextExpanded, setIsUrgentMessageTextExpanded] =
    useState(false);

  const closeUrgentMessagePopup = () => {
    setIsUrgentMessageTextExpanded(false);
  };

  const onUrgentMessageClick = (isLargeText: boolean) => {
    if (isLargeText) {
      setIsUrgentMessageTextExpanded(true);
    }
  };

  let isUrgentMessageVisible = false;
  const renderUrgentMessageBanner = () => {
    if (!chatData.chatHours?.urgentMessageText) {
      return null;
    }

    const characterLimit = 80;

    const isLargeText =
      chatData.chatHours?.urgentMessageText.length > characterLimit;

    let bannerText = chatData.chatHours?.urgentMessageText;
    if (isLargeText) {
      const lastCharacterPosition =
        chatData.chatHours?.urgentMessageText.indexOf(" ", characterLimit) ===
        -1
          ? chatData.chatHours?.urgentMessageText.length
          : chatData.chatHours?.urgentMessageText.indexOf(" ", characterLimit);
      bannerText =
        chatData.chatHours?.urgentMessageText.substring(
          0,
          lastCharacterPosition
        ) + "...";
    }

    isUrgentMessageVisible = true;

    return (
      <UrgentMessageBanner
        id="urgent-message"
        onClick={() => onUrgentMessageClick(isLargeText)}
        isLargeText={isLargeText}
      >
        <UrgentMessageInfoImage
          src={
            services.themes.currentTheme.assets.images.urgent_message_icon_svg
          }
          isRtl={isRtl}
        />
        <UrgentMessageText
          dangerouslySetInnerHTML={{ __html: bannerText ? bannerText : "" }}
          isRtl={isRtl}
        ></UrgentMessageText>
      </UrgentMessageBanner>
    );
  };

  const isPlayerLoggedIn = playerService.playerData != undefined;
  const isMrg = languageService.translate(services.themes.currentTheme.textKeys.hiMessage) === "";

  return (
    <ContainerMain>
      <NavigationBarContainer>
        <CloseButton onClick={onCloseClick} id="close-helpcenter-button">
          <CloseButtonSVG />
        </CloseButton>
        <HeaderText id="chat_title">
          {languageService.translate("Help_Center_Header_Text")}
        </HeaderText>
      </NavigationBarContainer>
      {isOutOfHoursPopupOpen && (
        <OutOfHoursPopup onClose={closeOutOfHoursPopup}></OutOfHoursPopup>
      )}
      {isUrgentMessageTextExpanded && (
        <UrgentMessagePopup
          id="urgetMessagePopup"
          onClose={closeUrgentMessagePopup}
        ></UrgentMessagePopup>
      )}
      {renderUrgentMessageBanner()}
   
      <AmandaIcon
        src={services.themes.currentTheme.assets.images.avatar_help_center_2x_png}
        isUrgentMessageVisible={isUrgentMessageVisible}
      ></AmandaIcon>
      <AmandaFAQ isRtl={isRtl}>
        <p id={"svgQuestionMark"}><QuestionMarkSVG /></p>
      </AmandaFAQ>


      <GreetingContainer>
        {!isMrg && 
        (<GreetingParagraph id="greetingParagraph">
          {languageService.translate(services.themes.currentTheme.textKeys.hiMessage)}
          {isPlayerLoggedIn && (
            <UserName>&nbsp;{playerService.playerData?.firstName}</UserName>
          )}
          !
        </GreetingParagraph>)}
        <GreetingParagraph>
          {languageService.translate(services.themes.currentTheme.textKeys.greetingMessage)}
          {isPlayerLoggedIn && isMrg && (
                <>
                <span>{', '}</span>
                <UserName>{playerService.playerData?.firstName}</UserName>
              </>
          )}?
        </GreetingParagraph>
      </GreetingContainer>
      {chatData.chatSettings?.FAQreportId && <FaqReport />}
      {renderButtons(shouldRenderChatButton ? renderChatButton : null, helpCenterService.hasFaq ? renderFAQButton : null, helpCenterService.hasEmail ? renderEmailButton :null)}
      {renderPageFooter()}
    </ContainerMain>
  );
});

//#endregion

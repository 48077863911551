import { Check } from "@sparkware/app-utils";
import {
  ActionID,
  AppIdentifiers,
  AppType,
  IAppLaunchInfo,
  IMessageBroker,
  ISessionTopicPayload,
  ITopic,
  ITopicHeaders,
  ITopicSubscription,
} from "@sparkware/uc-sdk-core";
import { servicesVersion } from "typescript";
import { isNotSet } from "../../utils/utility";
import { ServiceBase } from "../service-base";
import { IServiceFactory } from "../service-factory.interface";
import {
  ErrorPayload,
  IMessageBrokerService,
  WidgetPublisher,
} from "./message-broker.interface";

export class MessageBrokerService
  extends ServiceBase
  implements IMessageBrokerService
{
  resizeObservers: ResizeObserver | undefined;
  constructor(
    private readonly _channels: IMessageBroker,
    private readonly _services: IServiceFactory
  ) {
    super(_services);
  }

  private _subscriptions: ITopicSubscription[] = [];

  subscribe(
    callback: (channels: IMessageBroker) => ITopicSubscription
  ): ITopicSubscription {
    const originalSubscription = callback(this._channels);
    this._subscriptions.push(originalSubscription);
    return {
      unsubscribe: () => {
        console.log("Unsubscribed from the message");
        const subscriptionIndex = this._subscriptions.findIndex(
          (s) => s === originalSubscription
        );
        if (subscriptionIndex >= 0) {
          this._subscriptions.splice(subscriptionIndex, 1);
        }
        originalSubscription.unsubscribe();
      },
    };
  }

  subscribeTo<T = ISessionTopicPayload>(
    topicFn: (channels: IMessageBroker) => ITopic<T>,
    payloadFn: (payload: T) => void
  ): ITopicSubscription {
    if (isNotSet(topicFn)) throw new Error("Topic function was not defined");

    return this.subscribe((c) =>
      topicFn(c).subscribe((payload) => payloadFn(payload))
    );
  }

  appError(appId: AppIdentifiers, payload?: ErrorPayload): void {
    this._channels.session.topics.appError.publish(
      {
        publisher: appId,
      },
      {
        appID: appId,
        correlationID: this.services.chatData.chatInitParameters?.correlationID,
        launchInfo: this._getLaunchInfoForEvents(),
        appType: AppType.App,
        ...payload,
        // we need to do something with this error
      }
    );
  }

  initFailed(
    appId: AppIdentifiers,
    payload: { errorCode: number; errorDescription: string }
  ) {
    const { appInitParameters } = this.services.applicationService;
    this._channels.session.topics.appInitFailed.publish(
      {
        publisher: appId,
      },
      {
        appID: appId,
        correlationID: appInitParameters?.correlationID,
        launchInfo: this._getLaunchInfoForEvents(),
        ...payload,
      }
    );
  }

  initSucceded(appId: AppIdentifiers, sessionId?: string) {
    const { appInitParameters } = this.services.applicationService;

    this._channels.session.topics.appInitSucceeded.publish(
      {
        publisher: appId,
      },
      {
        appID: appId,
        correlationID: appInitParameters?.correlationID,
        launchInfo: this._getLaunchInfoForEvents(),
        sessionID: sessionId,
      }
    );
  }

  appClosed(
    appId: AppIdentifiers,
    correlationId: string,
    launchInfo?: IAppLaunchInfo
  ) {
    const { appInitParameters } = this.services.applicationService;
    this._channels.session.topics.appClosed.publish(
      {
        publisher: appId,
      },
      {
        appID: appId,
        correlationID: correlationId ?? appInitParameters?.correlationID,
        closeReason: "user_closed_app",
        launchInfo: this._getLaunchInfoForEvents(),

        // refactor for sending data which was received at the startup
        // launchInfo: {
        //     channel: { area: AreaType.button, source: AppIdentifiers.CrChat, element: "" },
        //     businessCorrelationID: correlationId,
        //     trigger: TriggerType.userSelection,
        //     sourceAppID: AppIdentifiers.CrChat,
        //     sourceAppVersion: process.env.REACT_APP_VERSION!
        // }
      }
    );
    this.resizeObservers?.disconnect();
    console.log("resize observer closed");
  }

  minimizeApp(appId: AppIdentifiers) {
    this._channels.session.topics.minimizeApp.publish(
      {
        publisher: appId,
      },
      {
        appID: appId,
      }
    );
    
    // this.makeBodyScrollableAgain();
  }

  appMinimized(appId: AppIdentifiers, headers: ITopicHeaders, appData?: any) {
    this._channels.session.topics.appMinimized.publish(headers, {
      appID: appId,
      appData: appData,
    });

  }

  performAction(
    appId: AppIdentifiers,
    actionId: ActionID,
    launchInfo?: IAppLaunchInfo,
    actionData?: any
  ) {
    const { appInitParameters } = this.services.applicationService;
    this._channels.navigation.topics.performAction.publish(
      {
        publisher: appId,
      },
      {
        actionID: actionId,
        correlationID: appInitParameters?.correlationID,
        launchInfo: launchInfo ?? this._getLaunchInfoForEvents(true),
        actionData: actionData,
      }
    );
  }

  chatActivity(appId: AppIdentifiers, messageCount: number) {
    this._channels.customerRelations.topics.chatActivity.publish(
      {
        publisher: appId,
      },
      { messagesCount: messageCount }
    );
  }

  dispose(): void {
    this._subscriptions.forEach((s) => s.unsubscribe());
    this._subscriptions = [];
  }

  /*
    This method will return a launch info modified for the events.
    Modifications: 
     - sequentialCorrelationId will be incremented before sending it
    */
  private _getLaunchInfoForEvents(
    shouldIncrement: boolean = false
  ): IAppLaunchInfo<any> {
    const { appInitParameters } = this.services.applicationService;
    if (Check.isNullOrUndefined(appInitParameters)) {
      throw new Error("Init parameters are not defined");
    }

    return {
      ...appInitParameters.applicationContext.launchInfo, //
      sequentialCorrelationID: shouldIncrement
        ? ++appInitParameters.applicationContext.launchInfo
            .sequentialCorrelationID!
        : appInitParameters.applicationContext.launchInfo
            .sequentialCorrelationID!,
    };
  }
}
